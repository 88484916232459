import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="O igri" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        To je odprtokodna igra ugibanja slovenskih besed, kjer je za vir
        uporabljen -{' '}
        <a
          href="https://viri.cjvt.si/sloleks/slv/"
          className="underline font-bold"
        >
          Sloleks
        </a>
        .
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 odmik-zgoraj">
        <b>Vsak dan</b> je za ugibanje na voljo <b>ena nova beseda</b>.
      </p>
    </BaseModal>
  )
}
