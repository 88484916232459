export const GAME_TITLE = 'BESEDNIK'

export const WIN_MESSAGES = [
  'Odlično opravljeno!',
  'Čudovito',
  'Dobro opravljeno!',
]
export const GAME_COPIED_MESSAGE = 'Rezultat igranja kopiran v odložišče'
export const ABOUT_GAME_MESSAGE = 'O igri'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Ni dovolj črk'
export const WORD_NOT_FOUND_MESSAGE = 'Ne najdem besede'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Pravilna beseda je bila ${solution}`
export const ENTER_TEXT = 'Potrdi'
export const DELETE_TEXT = 'Briši'
export const STATISTICS_TITLE = 'Statistika'
export const GUESS_DISTRIBUTION_TEXT = 'Porazdelitev ugibanja'
export const NEW_WORD_TEXT = 'Nova beseda čez'
export const SHARE_TEXT = 'Deljenje'
export const TOTAL_TRIES_TEXT = 'Skupno število poskusov'
export const SUCCESS_RATE_TEXT = 'Stopnja uspešnosti'
export const CURRENT_STREAK_TEXT = 'Trenutni niz'
export const BEST_STREAK_TEXT = 'Najboljši niz'
