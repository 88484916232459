export const WORDS = [
  'polst',
  'črevo',
  'šteti',
  'iznad',
  'pohod',
  'broša',
  'ideja',
  'pažev',
  'peven',
  'rigač',
  'cepin',
  'kalus',
  'točka',
  'občan',
  'čajka',
  'saden',
  'dorka',
  'sesec',
  'zidek',
  'čaren',
  'kreda',
  'oseka',
  'dokaj',
  'beluš',
  'rubin',
  'držek',
  'blisk',
  'skopo',
  'metla',
  'ustka',
  'pozer',
  'pivka',
  'mreti',
  'kršen',
  'peten',
  'tetka',
  'ritka',
  'medij',
  'malta',
  'bonus',
  'organ',
  'ujeti',
  'rival',
  'repuh',
  'sovin',
  'ponos',
  'kurji',
  'podrt',
  'dečev',
  'braka',
  'zenit',
  'gibek',
  'azola',
  'cepek',
  'ločba',
  'goban',
  'apnen',
  'vezaj',
  'močen',
  'nagon',
  'pasuš',
  'levič',
  'vijak',
  'preja',
  'orden',
  'bahat',
  'puški',
  'zmeti',
  'fagov',
  'osuti',
  'delan',
  'fotka',
  'renče',
  'jesti',
  'mokar',
  'težec',
  'oglav',
  'čedra',
  'vrisk',
  'jojme',
  'tovor',
  'ekran',
  'rakev',
  'trdeč',
  'lezti',
  'akord',
  'iztek',
  'videz',
  'tarča',
  'gobji',
  'dedek',
  'sploh',
  'pajek',
  'dočim',
  'zažig',
  'pašen',
  'zobec',
  'sirek',
  'drnec',
  'nežid',
  'bajka',
  'danka',
  'orgle',
  'štant',
  'pozoj',
  'oteti',
  'sečen',
  'vinta',
  'tatov',
  'pleva',
  'džins',
  'poraz',
  'kafič',
  'mušji',
  'breme',
  'odliv',
  'nočno',
  'ovčar',
  'rovar',
  'nadut',
  'sisal',
  'pošel',
  'nočka',
  'basen',
  'jakov',
  'bajer',
  'bunka',
  'lavor',
  'fluor',
  'glans',
  'polis',
  'stisk',
  'diana',
  'ujeda',
  'čutje',
  'sodec',
  'obuti',
  'botra',
  'sigov',
  'sedem',
  'kolek',
  'ajdov',
  'ukrep',
  'pampa',
  'snaha',
  'rahlo',
  'magik',
  'balet',
  'dizel',
  'scati',
  'ajden',
  'levov',
  'irsko',
  'babin',
  'kaver',
  'logor',
  'vidno',
  'spran',
  'kavka',
  'bongo',
  'teško',
  'koški',
  'vitek',
  'deroč',
  'vosek',
  'rejon',
  'motor',
  'okraj',
  'kuzla',
  'čuden',
  'oglen',
  'datum',
  'olein',
  'grdun',
  'patos',
  'mačka',
  'drevo',
  'obara',
  'vanga',
  'bojda',
  'harem',
  'magot',
  'čober',
  'želva',
  'kuhan',
  'taksi',
  'draga',
  'mavec',
  'tanek',
  'molža',
  'čedno',
  'ohati',
  'sušno',
  'kobra',
  'blaže',
  'ročno',
  'copot',
  'šivan',
  'tipka',
  'tepec',
  'mango',
  'tožen',
  'lučka',
  'žabon',
  'tesan',
  'keton',
  'vitra',
  'lanov',
  'muhav',
  'flora',
  'maslo',
  'paren',
  'zvrst',
  'pasus',
  'drsen',
  'smuka',
  'gorek',
  'vomer',
  'maper',
  'žmula',
  'gnada',
  'bacil',
  'smeje',
  'panda',
  'gonja',
  'ostva',
  'aloja',
  'krota',
  'vozeč',
  'ponev',
  'gmota',
  'sodra',
  'jidiš',
  'koker',
  'bukov',
  'odlom',
  'pisen',
  'tajna',
  'ajdin',
  'janež',
  'golen',
  'nomad',
  'ribič',
  'cikel',
  'vaški',
  'kitka',
  'tulij',
  'tepež',
  'srnji',
  'lokal',
  'larva',
  'obesa',
  'učnik',
  'trhle',
  'kabel',
  'hihot',
  'kuhar',
  'pršut',
  'pluča',
  'rasen',
  'puter',
  'empir',
  'povod',
  'nasut',
  'šejek',
  'malus',
  'ameba',
  'ličje',
  'fukač',
  'hecen',
  'odmik',
  'pasha',
  'oktet',
  'kujav',
  'mrlič',
  'strig',
  'oglat',
  'golač',
  'žehte',
  'fenek',
  'trpin',
  'gorje',
  'pijan',
  'nihaj',
  'pizda',
  'lipid',
  'veslo',
  'erbij',
  'bedno',
  'upati',
  'mesek',
  'lisec',
  'ustna',
  'tokej',
  'saldo',
  'frape',
  'odkup',
  'kužek',
  'papež',
  'izmit',
  'orlov',
  'loden',
  'kakor',
  'nečak',
  'rusko',
  'bičji',
  'putka',
  'nasek',
  'zaboj',
  'poema',
  'žarek',
  'pasji',
  'meček',
  'sonet',
  'lemur',
  'kodon',
  'denar',
  'himna',
  'nafta',
  'rjavo',
  'revno',
  'tilda',
  'fetiš',
  'atkov',
  'ampak',
  'brski',
  'skica',
  'narod',
  'doren',
  'želeč',
  'pomrl',
  'faren',
  'dulec',
  'srčev',
  'zarja',
  'zahod',
  'ležen',
  'uvala',
  'viški',
  'stres',
  'bočno',
  'skuša',
  'manul',
  'cmere',
  'ajbiš',
  'sidro',
  'časen',
  'serov',
  'rilec',
  'barit',
  'raven',
  'kriče',
  'nariv',
  'zelen',
  'ultra',
  'oslez',
  'komik',
  'nožič',
  'pisun',
  'polje',
  'nežen',
  'rovka',
  'gvano',
  'bivak',
  'bajen',
  'meten',
  'garje',
  'krema',
  'jedrn',
  'šelak',
  'ravno',
  'limfa',
  'vdolž',
  'grlce',
  'potka',
  'notes',
  'najem',
  'sifon',
  'guben',
  'bager',
  'dušeč',
  'koleb',
  'perut',
  'pišče',
  'sedij',
  'vežen',
  'mamba',
  'bruno',
  'jodat',
  'izhod',
  'tokoj',
  'čekan',
  'kapič',
  'sving',
  'metal',
  'foyer',
  'radža',
  'buler',
  'anona',
  'drevi',
  'sojen',
  'delno',
  'švist',
  'penja',
  'odcep',
  'medle',
  'hrbet',
  'gužva',
  'polet',
  'vtisk',
  'lebde',
  'gusle',
  'miška',
  'iskro',
  'fetva',
  'pičel',
  'bridž',
  'cesar',
  'sneti',
  'ukana',
  'stvar',
  'safir',
  'dingo',
  'tevje',
  'bikec',
  'šeški',
  'lepra',
  'amper',
  'trhlo',
  'krača',
  'grško',
  'kapra',
  'obrok',
  'čepek',
  'nebel',
  'opran',
  'oplet',
  'ninja',
  'našit',
  'sejen',
  'obliv',
  'pohan',
  'krivo',
  'borat',
  'loški',
  'zoben',
  'misel',
  'skube',
  'brest',
  'bogat',
  'požrt',
  'mrena',
  'telop',
  'iziti',
  'nežin',
  'ajati',
  'oprog',
  'krvav',
  'stkan',
  'oddih',
  'moher',
  'bolid',
  'mraze',
  'nateg',
  'pesin',
  'hlače',
  'napit',
  'košek',
  'bajta',
  'palec',
  'brana',
  'sesan',
  'mozeg',
  'kupen',
  'čezme',
  'čimža',
  'upnik',
  'patas',
  'topeč',
  'žehta',
  'dejan',
  'svišč',
  'noseč',
  'potop',
  'omelo',
  'junak',
  'kopen',
  'vezan',
  'rebrn',
  'širom',
  'šakal',
  'češko',
  'često',
  'kozak',
  'taleč',
  'rimar',
  'hopla',
  'zgaga',
  'zgnit',
  'skejt',
  'plaho',
  'tenko',
  'vreča',
  'avion',
  'kisik',
  'rdeče',
  'najsi',
  'klump',
  'tožeč',
  'profi',
  'suhec',
  'obala',
  'raper',
  'tržan',
  'vitje',
  'kupec',
  'mokro',
  'bodro',
  'zgoda',
  'resda',
  'pečen',
  'nadme',
  'lajna',
  'bivši',
  'flaša',
  'pasat',
  'vogal',
  'tečno',
  'silen',
  'bijoč',
  'igeln',
  'sekta',
  'drape',
  'hajka',
  'pinja',
  'rasti',
  'onile',
  'škrbe',
  'sitec',
  'psalm',
  'mikan',
  'hrust',
  'larov',
  'pokop',
  'kampo',
  'vloga',
  'pojem',
  'zarek',
  'punca',
  'koprc',
  'purin',
  'tešek',
  'odlog',
  'čvrst',
  'velik',
  'kalij',
  'boreč',
  'popek',
  'dildo',
  'preča',
  'pokaz',
  'rožen',
  'jesen',
  'šleva',
  'otava',
  'pošta',
  'znova',
  'puhek',
  'rački',
  'garda',
  'rosno',
  'vzpet',
  'počen',
  'bilka',
  'moreč',
  'truga',
  'risba',
  'ranar',
  'cerij',
  'cesta',
  'odpor',
  'korar',
  'trpek',
  'modno',
  'kopan',
  'žagar',
  'klika',
  'strme',
  'tekma',
  'volar',
  'tečka',
  'petek',
  'drong',
  'elita',
  'sajen',
  'gorat',
  'šolen',
  'sinus',
  'kanov',
  'pacek',
  'doseg',
  'jedko',
  'debut',
  'zglob',
  'kasko',
  'tisov',
  'soben',
  'načrt',
  'atlet',
  'heroj',
  'preša',
  'alkil',
  'cirus',
  'gluho',
  'škurh',
  'gusar',
  'kokon',
  'lišaj',
  'keson',
  'kefir',
  'rešen',
  'redeč',
  'cajna',
  'lesno',
  'hresk',
  'mrest',
  'odbor',
  'falos',
  'zaris',
  'stepa',
  'batek',
  'tonus',
  'vršen',
  'petič',
  'cigan',
  'režim',
  'komat',
  'fluat',
  'hiška',
  'silos',
  'čuječ',
  'pumin',
  'idiom',
  'ihteč',
  'sniti',
  'slava',
  'agrum',
  'motno',
  'jesih',
  'grbav',
  'samit',
  'izraz',
  'cimer',
  'gadji',
  'žeruh',
  'metan',
  'seraj',
  'falus',
  'frača',
  'lasec',
  'žilav',
  'jezno',
  'derak',
  'brhek',
  'votek',
  'naglo',
  'takle',
  'olika',
  'brzda',
  'tuleč',
  'zizek',
  'kraja',
  'ladja',
  'sleči',
  'kvaga',
  'mezeč',
  'laket',
  'gazen',
  'nižaj',
  'vadeč',
  'hipen',
  'vodeč',
  'vojna',
  'ribin',
  'heker',
  'kadet',
  'tepka',
  'nekov',
  'sepsa',
  'čužka',
  'drget',
  'medel',
  'unški',
  'foton',
  'vikar',
  'oglar',
  'pipar',
  'vprek',
  'redek',
  'pičen',
  'srpan',
  'boter',
  'nimfa',
  'konec',
  'video',
  'časih',
  'sklop',
  'mrtvo',
  'komaj',
  'premo',
  'rušeč',
  'žegen',
  'težen',
  'kubus',
  'javor',
  'bičan',
  'okrov',
  'plata',
  'bober',
  'silno',
  'ročka',
  'zebra',
  'dihur',
  'mečar',
  'breda',
  'pršič',
  'reber',
  'budra',
  'bodoč',
  'notar',
  'kačji',
  'racak',
  'juhej',
  'učiti',
  'hvala',
  'rapir',
  'desen',
  'dozer',
  'grupa',
  'vstop',
  'oplen',
  'pomit',
  'ovčin',
  'sitka',
  'koder',
  'salun',
  'maček',
  'epika',
  'pedic',
  'šilar',
  'noben',
  'opast',
  'napoj',
  'držeč',
  'belin',
  'iktus',
  'četrt',
  'sitce',
  'špica',
  'otrov',
  'ožeti',
  'izlit',
  'kader',
  'bazen',
  'loven',
  'zoprn',
  'borec',
  'čudno',
  'izvoz',
  'murka',
  'gavge',
  'vlaga',
  'pravi',
  'angel',
  'husit',
  'kolaž',
  'fagot',
  'geslo',
  'ogret',
  'torba',
  'dever',
  'adijo',
  'bofor',
  'pager',
  'žeton',
  'golša',
  'tlesk',
  'volan',
  'eleat',
  'lubje',
  'butik',
  'nalom',
  'vdati',
  'kroše',
  'gobec',
  'kanec',
  'iztok',
  'veber',
  'nedra',
  'nesit',
  'odmrl',
  'aziat',
  'kolik',
  'natrt',
  'april',
  'kanon',
  'izves',
  'bebav',
  'vodik',
  'zator',
  'berma',
  'žilje',
  'salon',
  'obzor',
  'rakec',
  'nemir',
  'idila',
  'psica',
  'hipno',
  'gabez',
  'kuter',
  'dovod',
  'brhko',
  'vozač',
  'oster',
  'kojot',
  'varpa',
  'obliž',
  'lezba',
  'oslad',
  'polka',
  'torej',
  'vesti',
  'surov',
  'sočen',
  'čigra',
  'zajla',
  'vsuti',
  'godno',
  'jadrn',
  'horuk',
  'petka',
  'nalit',
  'kužno',
  'metek',
  'temno',
  'diler',
  'zetov',
  'pešak',
  'hemin',
  'kipeč',
  'rjove',
  'sreča',
  'trpko',
  'haiku',
  'indic',
  'mezda',
  'bledo',
  'nadev',
  'otrok',
  'nelep',
  'sveča',
  'žabji',
  'pajac',
  'plato',
  'kumin',
  'vleči',
  'japok',
  'odpis',
  'zibel',
  'urnik',
  'ožiti',
  'bazar',
  'brati',
  'strip',
  'firer',
  'burek',
  'polos',
  'nikdo',
  'ulema',
  'gniti',
  'barva',
  'muren',
  'sarin',
  'oviti',
  'niala',
  'navoz',
  'lahno',
  'dudar',
  'kobal',
  'ikona',
  'serak',
  'ješče',
  'soški',
  'miško',
  'blato',
  'pleti',
  'mucek',
  'podan',
  'apnik',
  'zapis',
  'žički',
  'repuš',
  'opiat',
  'kljub',
  'zatič',
  'goste',
  'modul',
  'skvoš',
  'gejša',
  'moder',
  'bahav',
  'vezen',
  'renij',
  'rezus',
  'slede',
  'kahla',
  'sirov',
  'gofov',
  'drago',
  'petje',
  'kaški',
  'venel',
  'žezlo',
  'krsta',
  'vitel',
  'speči',
  'lapor',
  'šapka',
  'izkaz',
  'gumen',
  'salpa',
  'votlo',
  'harza',
  'dojka',
  'ziden',
  'smode',
  'vršič',
  'imeti',
  'okrog',
  'delen',
  'phati',
  'trska',
  'zanič',
  'trips',
  'gnida',
  'kemik',
  'penat',
  'mamin',
  'ušata',
  'cvete',
  'trije',
  'mulec',
  'anali',
  'ščene',
  'skvot',
  'kajla',
  'veški',
  'kalup',
  'devon',
  'kotva',
  'bitje',
  'črvič',
  'ritje',
  'žetev',
  'hmelj',
  'višaj',
  'lunin',
  'katar',
  'trnje',
  'bebec',
  'tabun',
  'zakop',
  'lasen',
  'dosje',
  'tkivo',
  'urbar',
  'baten',
  'revež',
  'pokol',
  'navor',
  'kikla',
  'vadba',
  'henri',
  'žagan',
  'redar',
  'pikov',
  'šnops',
  'ubiti',
  'betel',
  'arbun',
  'nizko',
  'debil',
  'zaton',
  'javen',
  'babka',
  'senca',
  'poseg',
  'sopeč',
  'risar',
  'potek',
  'klinč',
  'elina',
  'poden',
  'kleče',
  'negdo',
  'devin',
  'hehet',
  'sajga',
  'menek',
  'tujec',
  'viski',
  'škamp',
  'izbor',
  'vagon',
  'kamba',
  'doneč',
  'dedov',
  'namot',
  'asket',
  'pikro',
  'talen',
  'klinc',
  'beluh',
  'izlet',
  'sadža',
  'dalja',
  'mucka',
  'moped',
  'bobak',
  'proza',
  'žvala',
  'donov',
  'molče',
  'kaliv',
  'potov',
  'miner',
  'pipec',
  'kalin',
  'tožno',
  'amnij',
  'vajin',
  'sezut',
  'tolst',
  'blues',
  'hulok',
  'porov',
  'talon',
  'počit',
  'grban',
  'menda',
  'izpad',
  'odlov',
  'kokin',
  'odnos',
  'zlepa',
  'jajce',
  'repik',
  'vznak',
  'ženin',
  'kubik',
  'kožuh',
  'kilav',
  'pasta',
  'zidec',
  'vresa',
  'židek',
  'štola',
  'glede',
  'hotno',
  'morda',
  'prhek',
  'nočen',
  'izvir',
  'čikar',
  'tresk',
  'cizek',
  'zadet',
  'pešec',
  'logik',
  'želen',
  'pojav',
  'čopič',
  'peron',
  'trans',
  'greti',
  'pobeg',
  'čukla',
  'marec',
  'rujen',
  'strup',
  'stroj',
  'otisk',
  'mleko',
  'sadra',
  'tesač',
  'sušen',
  'zadek',
  'kiang',
  'frend',
  'lipom',
  'nekaj',
  'pesar',
  'zasip',
  'vzgon',
  'akril',
  'stint',
  'goneč',
  'larfa',
  'čopka',
  'škric',
  'zapor',
  'črtež',
  'sorta',
  'polir',
  'žleza',
  'pobec',
  'lahek',
  'smeti',
  'malar',
  'trten',
  'omela',
  'pelir',
  'mezon',
  'kiten',
  'cekin',
  'horda',
  'riški',
  'volov',
  'malik',
  'ogled',
  'lenič',
  'samol',
  'nitje',
  'zelot',
  'nejud',
  'riban',
  'nandu',
  'pinta',
  'slana',
  'naval',
  'čtivo',
  'vreči',
  'brode',
  'leska',
  'obhod',
  'atlas',
  'deber',
  'zvest',
  'čopek',
  'risov',
  'ravan',
  'kozin',
  'modus',
  'pobit',
  'igran',
  'znati',
  'kopač',
  'irski',
  'takin',
  'cibet',
  'amija',
  'ničev',
  'odlok',
  'žlebe',
  'dovoz',
  'frčeč',
  'pazen',
  'vrvje',
  'proba',
  'lamin',
  'drzno',
  'dober',
  'minul',
  'glade',
  'pušča',
  'bičar',
  'pesek',
  'baron',
  'ksiht',
  'ludit',
  'jelov',
  'vafel',
  'irbis',
  'čezse',
  'serif',
  'gluon',
  'kačje',
  'frank',
  'pleče',
  'nujno',
  'salak',
  'tjale',
  'utica',
  'komar',
  'prdec',
  'roški',
  'obrus',
  'salto',
  'repak',
  'kakov',
  'ustaš',
  'danes',
  'scvrt',
  'sovir',
  'polip',
  'kulan',
  'rekoč',
  'boršč',
  'sučen',
  'jadro',
  'rarog',
  'pasen',
  'natič',
  'poboj',
  'zbati',
  'polom',
  'riman',
  'buden',
  'senik',
  'bored',
  'pasek',
  'ruški',
  'pirit',
  'mreže',
  'mrtev',
  'kolač',
  'greda',
  'milen',
  'sejem',
  'karta',
  'jahta',
  'cerje',
  'gajba',
  'lečen',
  'zakaj',
  'slama',
  'izsek',
  'žvenk',
  'dotik',
  'kažoč',
  'pelin',
  'razor',
  'gosti',
  'lajik',
  'konto',
  'mučno',
  'sovec',
  'zamet',
  'kunji',
  'vajen',
  'trsen',
  'oslov',
  'zunaj',
  'krama',
  'mesno',
  'esaul',
  'besen',
  'sovka',
  'jedek',
  'batič',
  'kodrc',
  'apeks',
  'himen',
  'nožen',
  'lakov',
  'nosan',
  'aroma',
  'titan',
  'žrebe',
  'javka',
  'popis',
  'pinot',
  'vlača',
  'žrtev',
  'špila',
  'kurec',
  'tekoč',
  'serap',
  'talin',
  'idiot',
  'izmed',
  'čeden',
  'splav',
  'rjast',
  'polič',
  'zakol',
  'čepeč',
  'pozno',
  'mevža',
  'črnka',
  'jedro',
  'blizu',
  'šegav',
  'dotok',
  'osnik',
  'trakt',
  'dular',
  'oklep',
  'zavih',
  'ebola',
  'kužen',
  'slave',
  'stati',
  'pokal',
  'lepak',
  'zanos',
  'izgon',
  'input',
  'agens',
  'ohlip',
  'nesti',
  'zopet',
  'lačen',
  'torus',
  'izliv',
  'sinod',
  'sagov',
  'sanke',
  'vodar',
  'debet',
  'zatik',
  'pitan',
  'morje',
  'mučen',
  'dežno',
  'pitje',
  'pegaz',
  'nošen',
  'tenda',
  'krhko',
  'glota',
  'rezko',
  'prost',
  'žepek',
  'vrvca',
  'zobat',
  'tonik',
  'krhek',
  'barij',
  'šopek',
  'ričet',
  'molek',
  'cipar',
  'talka',
  'odred',
  'plača',
  'kamor',
  'tenor',
  'molar',
  'vznik',
  'ožina',
  'vreme',
  'zamah',
  'keper',
  'mrzko',
  'izvor',
  'mezeg',
  'džudo',
  'venec',
  'umbra',
  'vedno',
  'bodič',
  'podoj',
  'apnar',
  'žalen',
  'bosti',
  'odvrt',
  'mično',
  'tempo',
  'povrh',
  'razum',
  'tamle',
  'topen',
  'mečen',
  'ragbi',
  'videč',
  'odsun',
  'ocena',
  'bekon',
  'obseg',
  'nalet',
  'astra',
  'folen',
  'koper',
  'obzir',
  'firma',
  'špeca',
  'sklač',
  'pocar',
  'koden',
  'aršin',
  'višek',
  'izbit',
  'vabeč',
  'nesen',
  'kocka',
  'odaja',
  'šverc',
  'utaja',
  'aspik',
  'stena',
  'katra',
  'frula',
  'opora',
  'afekt',
  'grlen',
  'osoja',
  'kloža',
  'elisa',
  'groza',
  'fluks',
  'sivka',
  'plosk',
  'kniga',
  'korak',
  'tarok',
  'rušje',
  'glosa',
  'rabin',
  'boren',
  'enako',
  'piker',
  'ftiza',
  'regin',
  'kamni',
  'kvark',
  'bobek',
  'parti',
  'plašč',
  'šinje',
  'sadje',
  'fičko',
  'cevje',
  'pokec',
  'grmič',
  'liska',
  'lumen',
  'pekov',
  'neven',
  'nožev',
  'mrzel',
  'šepav',
  'možno',
  'komis',
  'grden',
  'vliti',
  'trgač',
  'kibla',
  'opera',
  'šibje',
  'duplo',
  'meter',
  'kučma',
  'vesel',
  'ruski',
  'iščoč',
  'skiro',
  'vžgan',
  'pirat',
  'šeboj',
  'deska',
  'tleti',
  'filit',
  'obiti',
  'sufle',
  'krmar',
  'suita',
  'starš',
  'šašev',
  'bebka',
  'zober',
  'gabro',
  'palež',
  'čisto',
  'vzdih',
  'ježev',
  'žaren',
  'dojet',
  'viola',
  'obora',
  'pošev',
  'tašča',
  'pelod',
  'kolos',
  'čutno',
  'bitno',
  'pirog',
  'jurko',
  'banka',
  'pošit',
  'zdriz',
  'lahen',
  'tedaj',
  'droga',
  'brlog',
  'vizum',
  'azoik',
  'kočar',
  'sinko',
  'infra',
  'lobus',
  'licej',
  'dokaz',
  'cedra',
  'balon',
  'grčav',
  'nugat',
  'krzno',
  'ruzak',
  'enook',
  'odpad',
  'proso',
  'vedež',
  'vbiti',
  'zaprt',
  'tukan',
  'šipon',
  'palma',
  'resno',
  'obrad',
  'šesti',
  'nožek',
  'smola',
  'burno',
  'varen',
  'povrg',
  'vreža',
  'kanal',
  'osmij',
  'hodža',
  'baral',
  'roden',
  'zavrt',
  'katun',
  'nosek',
  'čepar',
  'omama',
  'mežik',
  'ustno',
  'hokej',
  'sveže',
  'vogel',
  'zbiti',
  'škrob',
  'gnoza',
  'preko',
  'buška',
  'sahib',
  'jeklo',
  'odgon',
  'sipek',
  'tenek',
  'košen',
  'bizon',
  'kadeč',
  'kavza',
  'pomor',
  'losos',
  'uriti',
  'ginko',
  'volja',
  'alibi',
  'sklic',
  'zdrav',
  'sekač',
  'mejen',
  'breza',
  'etika',
  'oblič',
  'kalno',
  'urica',
  'eocen',
  'šamot',
  'šolar',
  'mukus',
  'vneti',
  'luški',
  'rodno',
  'vešče',
  'juriš',
  'zgled',
  'demon',
  'kriti',
  'kotel',
  'prama',
  'human',
  'sijaj',
  'zadaj',
  'golče',
  'čefur',
  'izmik',
  'majav',
  'murva',
  'dojen',
  'kasta',
  'golič',
  'kajne',
  'dinja',
  'jasli',
  'pomik',
  'kelat',
  'vizir',
  'bravo',
  'penez',
  'bakla',
  'breka',
  'bista',
  'lepek',
  'ocvrt',
  'požar',
  'izris',
  'ivrit',
  'metin',
  'mišak',
  'padec',
  'tesno',
  'curry',
  'košat',
  'vedoč',
  'sklon',
  'madež',
  'kopne',
  'seraf',
  'pilar',
  'rodeo',
  'odvoz',
  'spaka',
  'letak',
  'rogoz',
  'hlade',
  'toren',
  'srček',
  'govor',
  'urban',
  'pogum',
  'monga',
  'kazen',
  'flirt',
  'zatok',
  'srčno',
  'papan',
  'izrez',
  'gorko',
  'micen',
  'fluid',
  'topič',
  'rogar',
  'fetus',
  'kinik',
  'glupo',
  'bodel',
  'sušeč',
  'rotor',
  'poiti',
  'peter',
  'trust',
  'oskar',
  'narok',
  'gliom',
  'bujen',
  'druid',
  'kodek',
  'ptica',
  'celin',
  'drink',
  'motet',
  'gibko',
  'toner',
  'izvit',
  'hoden',
  'trapa',
  'ubogo',
  'burza',
  'mejak',
  'rubež',
  'tadva',
  'dioda',
  'odmet',
  'opica',
  'bobov',
  'žurer',
  'uliti',
  'prior',
  'rovaš',
  'ester',
  'habit',
  'roven',
  'vštet',
  'zidan',
  'dalje',
  'pepel',
  'mešič',
  'piano',
  'prion',
  'izšel',
  'tetin',
  'porno',
  'hrošč',
  'tolar',
  'tanin',
  'bolan',
  'možen',
  'aktin',
  'moker',
  'mezga',
  'zamaz',
  'eksot',
  'mecen',
  'milja',
  'voden',
  'obris',
  'bolno',
  'uklet',
  'šibek',
  'zajec',
  'unija',
  'ločje',
  'osama',
  'borša',
  'trobe',
  'rožno',
  'žepar',
  'napad',
  'izkop',
  'ogriz',
  'tisoč',
  'kolon',
  'mehur',
  'zrelo',
  'joška',
  'hrušč',
  'imbus',
  'čuten',
  'berda',
  'sever',
  'talec',
  'banov',
  'semiš',
  'galun',
  'vidik',
  'krpat',
  'zagib',
  'upliv',
  'boben',
  'mrzlo',
  'pečat',
  'gadov',
  'kitov',
  'ornat',
  'mlaja',
  'forma',
  'ometi',
  'polog',
  'obraz',
  'lopov',
  'afiks',
  'učeče',
  'julij',
  'izven',
  'fonem',
  'tešče',
  'žagin',
  'belič',
  'reklo',
  'ponik',
  'mulin',
  'saško',
  'nekak',
  'sabor',
  'bonec',
  'foter',
  'krški',
  'nakit',
  'pesen',
  'malan',
  'jedec',
  'rizma',
  'mamka',
  'dleto',
  'naliv',
  'kamin',
  'izžet',
  'pesto',
  'točaj',
  'geoda',
  'durov',
  'žepen',
  'celom',
  'lirik',
  'lizol',
  'toneč',
  'pelet',
  'oglas',
  'žreti',
  'siten',
  'rampa',
  'izrek',
  'kibic',
  'kirij',
  'hrope',
  'srpič',
  'titel',
  'lipan',
  'kovan',
  'kliti',
  'možek',
  'podlo',
  'tačka',
  'tkati',
  'marža',
  'lepko',
  'tesar',
  'kombi',
  'zoper',
  'rjave',
  'kolut',
  'opoka',
  'stoma',
  'smelo',
  'furan',
  'slovo',
  'futon',
  'opiti',
  'mahač',
  'iskra',
  'harfa',
  'bičev',
  'indij',
  'točno',
  'poper',
  'iglan',
  'gneča',
  'pasoč',
  'ziher',
  'mišji',
  'obrat',
  'trušč',
  'neuko',
  'holin',
  'ostro',
  'gaber',
  'deblo',
  'vsled',
  'salsa',
  'blond',
  'truma',
  'adult',
  'oslar',
  'dotis',
  'moden',
  'selec',
  'prsat',
  'repek',
  'obrit',
  'ličen',
  'risan',
  'hodec',
  'zmeda',
  'jerob',
  'gibon',
  'rudar',
  'arbon',
  'arzen',
  'harač',
  'živež',
  'kečap',
  'regal',
  'zveza',
  'zalet',
  'prača',
  'curij',
  'puška',
  'vdaja',
  'sodin',
  'sinje',
  'tumor',
  'fenil',
  'bajno',
  'balsa',
  'obran',
  'grozd',
  'rafal',
  'detel',
  'sršen',
  'nared',
  'hišen',
  'rajni',
  'vrtež',
  'komit',
  'okvir',
  'šansa',
  'rozga',
  'kufer',
  'takoj',
  'ježek',
  'objed',
  'pupek',
  'pavji',
  'rakun',
  'nikak',
  'lačno',
  'barža',
  'lugov',
  'glina',
  'edikt',
  'sanja',
  'odpev',
  'saten',
  'kreša',
  'rožje',
  'serec',
  'ležaj',
  'dušik',
  'rabat',
  'ataka',
  'liček',
  'jetra',
  'mulat',
  'žvižg',
  'kosov',
  'besno',
  'grbež',
  'bodeč',
  'saški',
  'čakma',
  'hasek',
  'mrhar',
  'vrsta',
  'pehar',
  'sojin',
  'fanov',
  'oteči',
  'baški',
  'pater',
  'sonda',
  'goreč',
  'objet',
  'lokav',
  'jošek',
  'stric',
  'mlado',
  'sesen',
  'grunt',
  'tesla',
  'astma',
  'lijak',
  'mreža',
  'mijav',
  'citat',
  'valat',
  'kokos',
  'zatem',
  'četen',
  'boleč',
  'oklic',
  'blago',
  'iztis',
  'bozon',
  'nonij',
  'helij',
  'lahet',
  'golec',
  'vozel',
  'lažen',
  'figov',
  'nečke',
  'ustje',
  'ondod',
  'jebač',
  'rezen',
  'ožati',
  'plebs',
  'najet',
  'vozen',
  'goral',
  'žetje',
  'dežen',
  'kulon',
  'tetra',
  'tekst',
  'jelen',
  'dolet',
  'packa',
  'šaman',
  'timus',
  'gavčo',
  'selen',
  'dreta',
  'tepih',
  'ločen',
  'čuvar',
  'vitez',
  'megla',
  'senat',
  'silak',
  'princ',
  'staja',
  'radio',
  'nalog',
  'davno',
  'nabor',
  'vihra',
  'žička',
  'počet',
  'jurta',
  'lučaj',
  'modem',
  'oštet',
  'užiti',
  'volek',
  'butan',
  'dajan',
  'bente',
  'fürer',
  'hromo',
  'puhel',
  'boški',
  'viden',
  'obroč',
  'dinar',
  'brbra',
  'mazut',
  'zreče',
  'buret',
  'bujon',
  'drzen',
  'obist',
  'vredu',
  'ardit',
  'alkar',
  'bujno',
  'gesta',
  'nagib',
  'trpno',
  'srpek',
  'rinka',
  'šarec',
  'bački',
  'invar',
  'moško',
  'brkač',
  'stran',
  'orjak',
  'gobav',
  'akson',
  'letev',
  'roler',
  'limit',
  'juhta',
  'brzeč',
  'šoben',
  'cetan',
  'laven',
  'lupač',
  'nemil',
  'jezen',
  'mrčes',
  'omara',
  'deški',
  'množe',
  'hotel',
  'prsen',
  'kljun',
  'zoran',
  'kilen',
  'vetje',
  'vihar',
]
