export const VALIDGUESSES = [
  'lireš',
  'fejst',
  'redno',
  'pufer',
  'nered',
  'cokla',
  'tokaj',
  'prvak',
  'napev',
  'felah',
  'kadar',
  'vrbji',
  'nonet',
  'ostrv',
  'oniks',
  'vsota',
  'jedež',
  'griva',
  'odeti',
  'seleč',
  'hiter',
  'zapet',
  'timol',
  'zadan',
  'argon',
  'zidar',
  'hudič',
  'horst',
  'pihan',
  'tekut',
  'pšeno',
  'kibuc',
  'čeber',
  'glava',
  'naris',
  'mršav',
  'ščegi',
  'mavra',
  'vrbin',
  'opeči',
  'mačje',
  'ostal',
  'oblok',
  'zmota',
  'uvelo',
  'ročaj',
  'belec',
  'trasa',
  'sunit',
  'mungo',
  'kravl',
  'vpeti',
  'pončo',
  'šoten',
  'tolpa',
  'suril',
  'babše',
  'prima',
  'ljubo',
  'slika',
  'nemoč',
  'stoje',
  'sedje',
  'hitro',
  'odzad',
  'largo',
  'lepen',
  'vroče',
  'jašek',
  'super',
  'koven',
  'reper',
  'drhte',
  'čukov',
  'hojla',
  'kokoš',
  'zrnje',
  'letos',
  'kunec',
  'moteč',
  'hlape',
  'brioš',
  'bučen',
  'motel',
  'lično',
  'makak',
  'punči',
  'rejka',
  'uhelj',
  'vzhod',
  'legar',
  'kalen',
  'brneč',
  'vržen',
  'ocean',
  'piton',
  'nunka',
  'koren',
  'ribje',
  'žareč',
  'čopar',
  'slano',
  'novic',
  'borka',
  'modro',
  'beton',
  'solen',
  'utrip',
  'deset',
  'črvar',
  'dekla',
  'ponva',
  'dosti',
  'sigma',
  'jodit',
  'lomeč',
  'joker',
  'obisk',
  'pekoč',
  'težko',
  'oliva',
  'agava',
  'tango',
  'pevka',
  'hišna',
  'ozeba',
  'goden',
  'zalom',
  'sivec',
  'zaiti',
  'izkup',
  'strok',
  'talar',
  'tnala',
  'zdoma',
  'kisel',
  'popov',
  'šekel',
  'renta',
  'pičlo',
  'nizek',
  'skoba',
  'trema',
  'kajža',
  'zapah',
  'albin',
  'maska',
  'kredo',
  'proja',
  'oolit',
  'freza',
  'kroje',
  'karat',
  'libra',
  'zakon',
  'sršaj',
  'kačin',
  'nakup',
  'gvelf',
  'jutro',
  'ugriz',
  'brzec',
  'pobot',
  'sifak',
  'mufti',
  'odmev',
  'klada',
  'pečka',
  'uleči',
  'ideal',
  'drenj',
  'pasar',
  'domek',
  'pista',
  'kavin',
  'spred',
  'pismo',
  'brleč',
  'mereč',
  'sežet',
  'paški',
  'tipov',
  'sočno',
  'ropar',
  'urhov',
  'dolit',
  'vzvod',
  'zloba',
  'luben',
  'rover',
  'daman',
  'kopun',
  'iznos',
  'gožev',
  'povoj',
  'rumba',
  'ždeti',
  'kožen',
  'trate',
  'sukač',
  'fondi',
  'omski',
  'dečji',
  'bojno',
  'režen',
  'zdeti',
  'deček',
  'lejdi',
  'babež',
  'novec',
  'žičen',
  'poves',
  'eozin',
  'tleče',
  'kanta',
  'bilje',
  'folat',
  'spati',
  'štumf',
  'dodan',
  'vazal',
  'napol',
  'grški',
  'bukev',
  'derbi',
  'sloka',
  'pršen',
  'begun',
  'medar',
  'finta',
  'sraka',
  'kujon',
  'žolna',
  'hanij',
  'kimav',
  'varan',
  'ileus',
  'moški',
  'roben',
  'ledik',
  'butec',
  'nihče',
  'isker',
  'bunda',
  'vrtek',
  'bedro',
  'kanat',
  'bojar',
  'odtis',
  'seksi',
  'oriti',
  'kramp',
  'izvin',
  'strah',
  'domet',
  'odeja',
  'pomoč',
  'sezam',
  'čišča',
  'zavit',
  'škrga',
  'hoteč',
  'božič',
  'menih',
  'lapis',
  'scela',
  'vdova',
  'zašit',
  'popit',
  'izvid',
  'pegat',
  'drseč',
  'totem',
  'priča',
  'muški',
  'farba',
  'uklon',
  'dokup',
  'kebab',
  'drtje',
  'mičen',
  'donos',
  'zmoči',
  'sodno',
  'lenta',
  'potem',
  'južen',
  'alkan',
  'čuteč',
  'bočen',
  'jager',
  'okapi',
  'oleat',
  'zrklo',
  'posek',
  'držaj',
  'binom',
  'bonit',
  'ničti',
  'lazar',
  'astat',
  'favna',
  'rožič',
  'tržen',
  'sukan',
  'vziti',
  'bruto',
  'epski',
  'tobak',
  'senen',
  'skrka',
  'krmen',
  'tanko',
  'čibuk',
  'cifra',
  'najin',
  'zamrl',
  'alkin',
  'žilen',
  'dacit',
  'slina',
  'sedež',
  'vpiti',
  'piten',
  'dečko',
  'poleg',
  'evnuh',
  'spust',
  'drvar',
  'bežen',
  'ugled',
  'vrtec',
  'lemež',
  'fušar',
  'šerpa',
  'plavž',
  'mošus',
  'dacar',
  'uvlek',
  'briti',
  'čepen',
  'težek',
  'lolek',
  'nagel',
  'kinin',
  'pedal',
  'dijak',
  'fenol',
  'tonem',
  'prtič',
  'daven',
  'lotos',
  'žiški',
  'semit',
  'sraga',
  'zasuk',
  'godba',
  'epoha',
  'izpis',
  'namen',
  'izbok',
  'fakin',
  'zaliv',
  'popon',
  'všiti',
  'šalca',
  'efekt',
  'apoen',
  'kršin',
  'zadrt',
  'manko',
  'sitno',
  'deden',
  'atest',
  'paroh',
  'rojak',
  'rašpa',
  'počep',
  'trden',
  'hiton',
  'roman',
  'soden',
  'srati',
  'semle',
  'vešec',
  'cagav',
  'mesto',
  'pozor',
  'mnogo',
  'perla',
  'doliv',
  'pujsa',
  'žabec',
  'bomba',
  'islam',
  'vrbar',
  'odkod',
  'šarža',
  'zrnce',
  'rutka',
  'trčen',
  'epsko',
  'porek',
  'nazaj',
  'draže',
  'čemaž',
  'mulji',
  'moleč',
  'roker',
  'naboj',
  'namaz',
  'lenuh',
  'vrvar',
  'lopen',
  'hudir',
  'timin',
  'sreda',
  'lesar',
  'rumov',
  'driča',
  'bdeti',
  'odvod',
  'koral',
  'avdio',
  'dimen',
  'start',
  'afera',
  'topot',
  'kopje',
  'bučno',
  'leglo',
  'lanen',
  'testo',
  'dedič',
  'banda',
  'ponor',
  'čepin',
  'štras',
  'hiteč',
  'doječ',
  'arija',
  'sobar',
  'liman',
  'važno',
  'sloko',
  'bivol',
  'život',
  'oljen',
  'lomen',
  'tožba',
  'kampa',
  'konzi',
  'nitar',
  'oralo',
  'mogoč',
  'toplo',
  'ulkus',
  'frata',
  'važič',
  'akant',
  'derač',
  'kruto',
  'rejec',
  'vinil',
  'dišeč',
  'avgur',
  'lesti',
  'spora',
  'razno',
  'beški',
  'ekcem',
  'gošča',
  'mirta',
  'pleme',
  'peneč',
  'meket',
  'žlica',
  'pesem',
  'lasek',
  'izpet',
  'pirka',
  'župen',
  'žužek',
  'blišč',
  'belež',
  'dolar',
  'iglar',
  'batik',
  'psiho',
  'majna',
  'čatež',
  'turov',
  'pomol',
  'večer',
  'kroki',
  'smuča',
  'vajet',
  'poduk',
  'masiv',
  'zajet',
  'tepen',
  'hipec',
  'masen',
  'sedeč',
  'klina',
  'suški',
  'grbec',
  'gobov',
  'sprva',
  'dudač',
  'odjek',
  'izsev',
  'kumis',
  'ozara',
  'poteg',
  'tička',
  'srbec',
  'cimet',
  'krilo',
  'rjuti',
  'odbit',
  'sicer',
  'tubul',
  'losov',
  'drobe',
  'izbuh',
  'biten',
  'liški',
  'golob',
  'dobro',
  'tlaka',
  'božji',
  'usnje',
  'liven',
  'karov',
  'zmaga',
  'daleč',
  'paket',
  'sinov',
  'bejba',
  'skuta',
  'vršac',
  'snoči',
  'slabo',
  'vatel',
  'genom',
  'pasti',
  'pegam',
  'teren',
  'varno',
  'legat',
  'rinež',
  'način',
  'rakov',
  'nevma',
  'padel',
  'riten',
  'citre',
  'jalov',
  'minus',
  'jetrn',
  'repat',
  'srdit',
  'opeka',
  'lampa',
  'terme',
  'oocit',
  'nadet',
  'luska',
  'nanos',
  'usoda',
  'bahač',
  'estet',
  'poriv',
  'tukaj',
  'kajti',
  'aleja',
  'betva',
  'mrkač',
  'trnač',
  'fensi',
  'zrnec',
  'pobov',
  'vozek',
  'cenik',
  'kafra',
  'rejen',
  'sipin',
  'bacek',
  'budno',
  'odkop',
  'belka',
  'odraz',
  'pleča',
  'oblit',
  'kupon',
  'neben',
  'akter',
  'radij',
  'kulak',
  'dušen',
  'tiček',
  'dolus',
  'polio',
  'ječar',
  'požet',
  'resen',
  'bikov',
  'vulva',
  'žajfa',
  'oglje',
  'dudka',
  'strmo',
  'kanja',
  'leten',
  'ruden',
  'arena',
  'palik',
  'lovka',
  'halva',
  'skavt',
  'treti',
  'piška',
  'vklop',
  'šiška',
  'priti',
  'bluza',
  'trzaj',
  'tulež',
  'šumen',
  'vezje',
  'aorta',
  'hamam',
  'kralj',
  'bodež',
  'krven',
  'kapus',
  'tršat',
  'talij',
  'arhiv',
  'vrček',
  'črviv',
  'boršt',
  'živec',
  'riček',
  'robat',
  'flota',
  'odkar',
  'plaža',
  'stoik',
  'beden',
  'fjord',
  'dieta',
  'mehek',
  'usuti',
  'nadse',
  'štala',
  'brada',
  'burja',
  'magov',
  'laški',
  'romar',
  'vlada',
  'kipec',
  'penis',
  'prošt',
  'tabla',
  'banja',
  'siveč',
  'trlja',
  'plima',
  'prede',
  'maten',
  'halon',
  'vnema',
  'rodij',
  'gazda',
  'veren',
  'notri',
  'velur',
  'bolha',
  'rezec',
  'setev',
  'izpod',
  'zoren',
  'zlato',
  'orati',
  'ribez',
  'izvod',
  'poteč',
  'merek',
  'varuh',
  'šipek',
  'izmet',
  'gosto',
  'lapuh',
  'mrzek',
  'pička',
  'liter',
  'pumpa',
  'možev',
  'gliva',
  'kosec',
  'album',
  'hafiz',
  'orala',
  'labod',
  'pasan',
  'mesen',
  'tiara',
  'deleč',
  'stopa',
  'arest',
  'biser',
  'žolca',
  'poren',
  'oditi',
  'carar',
  'čudak',
  'tržno',
  'eparh',
  'dečva',
  'minij',
  'nižek',
  'pisan',
  'zavet',
  'duola',
  'banjo',
  'risji',
  'trgec',
  'plise',
  'medme',
  'kacet',
  'cirka',
  'damin',
  'krote',
  'zajka',
  'enota',
  'bitka',
  'krtek',
  'grobo',
  'email',
  'nosat',
  'balin',
  'čuvan',
  'nežno',
  'židko',
  'nadir',
  'šurov',
  'zasek',
  'ovčji',
  'dlesk',
  'litij',
  'kopel',
  'kamšt',
  'videc',
  'radar',
  'alkov',
  'čmrlj',
  'anala',
  'usten',
  'usran',
  'disko',
  'izdan',
  'dežek',
  'smilj',
  'pomen',
  'kvant',
  'cimra',
  'skink',
  'trsov',
  'kakav',
  'kliše',
  'deist',
  'fiola',
  'mesec',
  'sesti',
  'škoda',
  'mašen',
  'cekas',
  'račka',
  'čipka',
  'šolan',
  'tikov',
  'pasiv',
  'lisar',
  'vzpon',
  'vrana',
  'kislo',
  'navar',
  'bolen',
  'peket',
  'whist',
  'grmeč',
  'jarem',
  'kegel',
  'črten',
  'orkan',
  'helot',
  'vanež',
  'sesut',
  'snaga',
  'kašča',
  'peška',
  'potep',
  'vrven',
  'žejen',
  'gavun',
  'prata',
  'daški',
  'pivot',
  'bedak',
  'tolči',
  'dirka',
  'lizin',
  'graja',
  'odriv',
  'plano',
  'ispit',
  'grušč',
  'krona',
  'stvor',
  'ritem',
  'capin',
  'mitra',
  'nakar',
  'begov',
  'navje',
  'buket',
  'bičje',
  'pavov',
  'proga',
  'pirin',
  'dreti',
  'turek',
  'hecno',
  'oblak',
  'sodar',
  'uteči',
  'koseč',
  'teror',
  'hoški',
  'orlič',
  'robek',
  'brkat',
  'obdan',
  'grint',
  'faran',
  'uspeh',
  'tajga',
  'živeč',
  'oštir',
  'finiš',
  'navoj',
  'jarek',
  'gnati',
  'cekar',
  'vojen',
  'tačas',
  'lonec',
  'osmič',
  'natis',
  'tujka',
  'nažrt',
  'kozav',
  'halit',
  'judež',
  'tiran',
  'zatrt',
  'kapar',
  'ježen',
  'borza',
  'noten',
  'fikus',
  'pezde',
  'hidra',
  'puhlo',
  'ločki',
  'podme',
  'uniat',
  'stava',
  'litje',
  'snuti',
  'steči',
  'inčun',
  'drozg',
  'cenen',
  'oljar',
  'racin',
  'tinta',
  'vodno',
  'bogov',
  'ekipa',
  'lajež',
  'halja',
  'party',
  'lakaj',
  'izdih',
  'tolik',
  'scalo',
  'bežeč',
  'stoti',
  'pline',
  'vreti',
  'delec',
  'sarus',
  'ploha',
  'vlaka',
  'siren',
  'desno',
  'lodra',
  'kalan',
  'jelka',
  'rujno',
  'gnjat',
  'vraža',
  'žrelo',
  'nasad',
  'mužik',
  'priba',
  'iskan',
  'sidrn',
  'hojca',
  'strel',
  'napet',
  'oddan',
  'terna',
  'cepec',
  'druga',
  'navit',
  'trava',
  'teden',
  'prhko',
  'preža',
  'vtkan',
  'vedro',
  'trpeč',
  'dezen',
  'vzbuh',
  'klima',
  'rolka',
  'trias',
  'svrha',
  'milje',
  'somov',
  'gotov',
  'sence',
  'vzlet',
  'odrez',
  'ugrez',
  'kovač',
  'fraza',
  'borno',
  'vaten',
  'ropot',
  'baker',
  'žarko',
  'izziv',
  'kajpa',
  'izpuh',
  'čigar',
  'blede',
  'papir',
  'virus',
  'rogat',
  'sosed',
  'levji',
  'volej',
  'odjem',
  'randi',
  'pilot',
  'ataše',
  'nekdo',
  'cinik',
  'viher',
  'srčen',
  'vrzel',
  'čakra',
  'jahač',
  'foaje',
  'odtok',
  'cofek',
  'vegan',
  'zgolj',
  'sokol',
  'pišoč',
  'šment',
  'osica',
  'ježeš',
  'topel',
  'večen',
  'bržda',
  'laško',
  'večno',
  'šilce',
  'ujema',
  'brošč',
  'zasut',
  'ribon',
  'nonin',
  'sulec',
  'brede',
  'podor',
  'joint',
  'krajt',
  'hrtov',
  'zibka',
  'beril',
  'boječ',
  'galeb',
  'nasip',
  'sveto',
  'lumna',
  'lutka',
  'tarov',
  'škrat',
  'freon',
  'globa',
  'livar',
  'lovec',
  'boder',
  'mišar',
  'sevka',
  'obšit',
  'špaga',
  'orant',
  'njiva',
  'obilo',
  'vince',
  'sirar',
  'teist',
  'biček',
  'vzmet',
  'dopis',
  'maron',
  'breča',
  'griža',
  'oblet',
  'praha',
  'galij',
  'maksi',
  'pavka',
  'lider',
  'plast',
  'agnat',
  'meden',
  'ohcet',
  'bulav',
  'fafač',
  'žiten',
  'jodid',
  'fosil',
  'dohod',
  'mačji',
  'hrana',
  'honda',
  'akati',
  'kurol',
  'sonar',
  'pomet',
  'drhal',
  'sežig',
  'kriza',
  'solza',
  'obvod',
  'model',
  'zliti',
  'oslič',
  'kiosk',
  'moten',
  'pečar',
  'pevec',
  'jerin',
  'orada',
  'žurka',
  'podse',
  'poten',
  'lučca',
  'medlo',
  'karma',
  'leden',
  'čigav',
  'črnuh',
  'kozel',
  'račji',
  'visok',
  'odprt',
  'rajža',
  'kerub',
  'rdeti',
  'edino',
  'krope',
  'bajza',
  'likar',
  'hulež',
  'poliv',
  'pivov',
  'panel',
  'zanka',
  'izteg',
  'gruda',
  'jušen',
  'biret',
  'neživ',
  'storž',
  'turen',
  'pekač',
  'vezir',
  'koten',
  'rajon',
  'niten',
  'sijoč',
  'prase',
  'tabor',
  'motiv',
  'nuden',
  'klovn',
  'buški',
  'bohot',
  'metež',
  'puder',
  'odveč',
  'judov',
  'curek',
  'veper',
  'humor',
  'peški',
  'redko',
  'išias',
  'vrbje',
  'drnič',
  'meški',
  'bežno',
  'skunk',
  'podel',
  'slone',
  'marka',
  'čižek',
  'deško',
  'režeč',
  'mambo',
  'sadež',
  'gašen',
  'nekje',
  'črtan',
  'mejaš',
  'živet',
  'odtod',
  'grbač',
  'zagon',
  'hodeč',
  'oriks',
  'krčen',
  'odvit',
  'vgrez',
  'črvov',
  'nerad',
  'rebus',
  'govno',
  'speče',
  'kasač',
  'mlaka',
  'biški',
  'resor',
  'ušica',
  'hamit',
  'hosta',
  'tipen',
  'dolek',
  'ducat',
  'jakna',
  'pizza',
  'možic',
  'kleno',
  'kaleč',
  'logar',
  'civil',
  'držač',
  'srbeč',
  'vrvež',
  'gojen',
  'kmalu',
  'godec',
  'makro',
  'junec',
  'črpan',
  'ležeč',
  'triko',
  'sekan',
  'obtok',
  'rakav',
  'lista',
  'prvič',
  'cucek',
  'etapa',
  'sedaj',
  'umiti',
  'major',
  'zalit',
  'omega',
  'ficek',
  'lažno',
  'rahel',
  'rebro',
  'beroč',
  'tajno',
  'trend',
  'silur',
  'gofer',
  'avans',
  'bisus',
  'kozji',
  'šibra',
  'zreti',
  'nazal',
  'sunek',
  'runda',
  'oblat',
  'optik',
  'prati',
  'rižek',
  'gekon',
  'svila',
  'vrata',
  'rižen',
  'stela',
  'nojev',
  'porok',
  'sarma',
  'šepet',
  'čreda',
  'načet',
  'norma',
  'aguti',
  'zmiti',
  'munec',
  'besne',
  'posel',
  'rosen',
  'trnat',
  'pogoj',
  'kapok',
  'možat',
  'rogač',
  'maral',
  'gumno',
  'knuta',
  'račun',
  'hunta',
  'odziv',
  'fizik',
  'odsev',
  'revir',
  'rizik',
  'drsno',
  'šefov',
  'tesen',
  'vodka',
  'zakup',
  'lojen',
  'dipol',
  'zakov',
  'namig',
  'umski',
  'tiger',
  'metil',
  'štrle',
  'ubran',
  'račak',
  'mapen',
  'sesek',
  'gumar',
  'ribji',
  'makov',
  'čelen',
  'blesk',
  'mesti',
  'ingot',
  'izpah',
  'pisač',
  'tapir',
  'odkos',
  'pozen',
  'pagat',
  'sredi',
  'feder',
  'odboj',
  'vojak',
  'encim',
  'ledje',
  'zožen',
  'mumps',
  'psiha',
  'lokus',
  'vgriz',
  'kalif',
  'turak',
  'brizg',
  'zazrt',
  'mizar',
  'reški',
  'nekod',
  'frfot',
  'revma',
  'gruča',
  'odpet',
  'potrt',
  'kočan',
  'robov',
  'bučka',
  'jasen',
  'dedno',
  'konus',
  'etuda',
  'rajda',
  'črtka',
  'zaseg',
  'kalež',
  'samec',
  'monal',
  'etaža',
  'cuker',
  'oholo',
  'učilo',
  'robot',
  'genij',
  'satje',
  'samba',
  'efira',
  'image',
  'mehko',
  'jutri',
  'trebe',
  'vešča',
  'zdrob',
  'počez',
  'oščep',
  'vidra',
  'mleti',
  'varoa',
  'zamik',
  'rondo',
  'zarod',
  'aplit',
  'odeon',
  'obvoz',
  'namok',
  'skoro',
  'miren',
  'nujen',
  'polit',
  'liana',
  'farma',
  'pluti',
  'tečen',
  'babji',
  'noter',
  'kapen',
  'zorno',
  'pleša',
  'zobač',
  'odmor',
  'ogeln',
  'misal',
  'pasma',
  'jedka',
  'forum',
  'magma',
  'krčma',
  'lizun',
  'vodeb',
  'proti',
  'farad',
  'delta',
  'zapit',
  'tiren',
  'raški',
  'zarit',
  'osten',
  'kobul',
  'vetrc',
  'čapka',
  'ihtav',
  'bokan',
  'trhel',
  'gvant',
  'giben',
  'pazeč',
  'sedlo',
  'kreol',
  'meneč',
  'padar',
  'pomig',
  'likof',
  'župan',
  'poziv',
  'perje',
  'mokrc',
  'prota',
  'čiček',
  'tarot',
  'zasun',
  'borba',
  'kisov',
  'rokav',
  'plaze',
  'posip',
  'bajsa',
  'cevka',
  'peder',
  'mesar',
  'sfera',
  'trola',
  'emajl',
  'vdeti',
  'malce',
  'češki',
  'aneks',
  'kvota',
  'salta',
  'raček',
  'fonon',
  'birič',
  'ondan',
  'mišek',
  'čuvaj',
  'sleng',
  'tifus',
  'zajem',
  'možak',
  'karan',
  'atrij',
  'vohun',
  'širen',
  'žival',
  'tnalo',
  'kavri',
  'ključ',
  'stolp',
  'eland',
  'balza',
  'duhec',
  'tekom',
  'krava',
  'inlet',
  'fakir',
  'piven',
  'oktan',
  'norka',
  'skorš',
  'pupin',
  'opeti',
  'kožar',
  'bojen',
  'naziv',
  'maser',
  'jeder',
  'argus',
  'satan',
  'vokal',
  'tečaj',
  'pogan',
  'dveri',
  'potok',
  'tunel',
  'trata',
  'lesen',
  'fazen',
  'umsko',
  'ovnov',
  'kurat',
  'buren',
  'lovor',
  'volna',
  'uščip',
  'sesač',
  'nahod',
  'omaka',
  'barka',
  'rihta',
  'luzer',
  'ročen',
  'torek',
  'spona',
  'rumen',
  'izzid',
  'backa',
  'poprh',
  'zgrda',
  'jereb',
  'jopič',
  'burka',
  'divan',
  'savna',
  'vzeti',
  'resje',
  'uredu',
  'trnov',
  'žepič',
  'junij',
  'kršec',
  'držka',
  'temen',
  'davek',
  'izjed',
  'pisno',
  'vrteč',
  'pisar',
  'zares',
  'zoreč',
  'fajfa',
  'sodba',
  'monin',
  'parta',
  'tajen',
  'žavba',
  'giški',
  'štart',
  'božec',
  'nagec',
  'serum',
  'krkon',
  'pelat',
  'čokat',
  'ovsen',
  'srnin',
  'stenj',
  'toast',
  'revše',
  'sukno',
  'buček',
  'kočič',
  'teran',
  'rečen',
  'pravo',
  'aksis',
  'fazan',
  'šumeč',
  'mazda',
  'adept',
  'muhar',
  'cepet',
  'terca',
  'močno',
  'blast',
  'selka',
  'manta',
  'ovira',
  'zavod',
  'anion',
  'treba',
  'debel',
  'menza',
  'vitko',
  'jasno',
  'dunit',
  'barok',
  'rasel',
  'pršec',
  'bavta',
  'kurir',
  'rakar',
  'kačar',
  'omika',
  'šerif',
  'averz',
  'javno',
  'kotač',
  'pohra',
  'torij',
  'porog',
  'ašram',
  'kakec',
  'bajar',
  'štula',
  'leški',
  'begav',
  'srbež',
  'shema',
  'lahko',
  'žleht',
  'gripa',
  'humus',
  'čukec',
  'gosli',
  'girus',
  'gonič',
  'zguba',
  'vpliv',
  'vodič',
  'česan',
  'taksa',
  'niški',
  'čajen',
  'loveč',
  'uvula',
  'betič',
  'sprej',
  'pluta',
  'šofer',
  'napis',
  'posut',
  'oljka',
  'ajvar',
  'goska',
  'slabe',
  'začet',
  'kosem',
  'sedmi',
  'gejev',
  'agama',
  'šport',
  'lunik',
  'sonce',
  'čelno',
  'pršeč',
  'pavza',
  'troha',
  'boden',
  'nadih',
  'izvan',
  'rojen',
  'ogenj',
  'kukec',
  'ambra',
  'mazač',
  'kasno',
  'paden',
  'scena',
  'mutec',
  'alpid',
  'kšeft',
  'loček',
  'pekel',
  'čudež',
  'jumbo',
  'latje',
  'kasen',
  'domov',
  'bedrn',
  'sinji',
  'štrik',
  'enaki',
  'očkov',
  'indri',
  'vzgib',
  'pisec',
  'kaval',
  'čenča',
  'vstal',
  'vseno',
  'telur',
  'vleka',
  'lokev',
  'puhec',
  'vrten',
  'polaj',
  'povit',
  'lozin',
  'srepe',
  'nabod',
  'oksid',
  'kolar',
  'malha',
  'triak',
  'hoten',
  'aktiv',
  'mivka',
  'rjuha',
  'votel',
  'trnek',
  'tulec',
  'vetrn',
  'lipov',
  'ovčka',
  'čorba',
  'zavoj',
  'bovla',
  'ovnič',
  'dukat',
  'ligaš',
  'kaven',
  'žerka',
  'nosen',
  'izvrg',
  'sauna',
  'jamar',
  'stoja',
  'liker',
  'rekši',
  'medse',
  'galop',
  'rezva',
  'halma',
  'kurba',
  'osmer',
  'nosač',
  'speti',
  'serka',
  'porod',
  'kamen',
  'nekoč',
  'jezik',
  'skala',
  'polno',
  'imidž',
  'abota',
  'koala',
  'dnina',
  'ulica',
  'klati',
  'popje',
  'črnec',
  'šotor',
  'talib',
  'kršje',
  'vrben',
  'jokav',
  'zidak',
  'slast',
  'zaman',
  'leteč',
  'vrišč',
  'skalp',
  'locen',
  'modrc',
  'norec',
  'izlov',
  'sovji',
  'pašta',
  'kdove',
  'fasan',
  'dogma',
  'valeč',
  'radič',
  'pusto',
  'ferit',
  'čunja',
  'okras',
  'brbot',
  'grudi',
  'fatva',
  'oseba',
  'zrnat',
  'avizo',
  'sirup',
  'sokot',
  'mokož',
  'salva',
  'drkač',
  'klapa',
  'makao',
  'važen',
  'radon',
  'somič',
  'gnajs',
  'dušno',
  'rimač',
  'gavda',
  'sešit',
  'anoda',
  'pogon',
  'žbica',
  'vršaj',
  'barje',
  'tekač',
  'česen',
  'teloh',
  'čislo',
  'mamut',
  'komet',
  'ličar',
  'divin',
  'hašiš',
  'repen',
  'vzrok',
  'ničla',
  'dativ',
  'kipar',
  'fojba',
  'smrad',
  'hitin',
  'goški',
  'takov',
  'vosat',
  'oltar',
  'nulti',
  'viseč',
  'pojoč',
  'torzo',
  'sesip',
  'pobič',
  'kajak',
  'komad',
  'gosji',
  'črnav',
  'sabat',
  'koata',
  'delež',
  'fešta',
  'sklep',
  'očala',
  'šibko',
  'cezij',
  'tatič',
  'trsje',
  'dedec',
  'tisti',
  'ceven',
  'tujek',
  'srpje',
  'židan',
  'sušec',
  'dlaka',
  'grelo',
  'inski',
  'filet',
  'lulek',
  'sejan',
  'zobar',
  'jodov',
  'požig',
  'celec',
  'sinek',
  'lager',
  'rižev',
  'sršeč',
  'pokoj',
  'sluga',
  'sipko',
  'havba',
  'peste',
  'kocen',
  'trnež',
  'lečje',
  'odsek',
  'motek',
  'avgit',
  'rezek',
  'gulag',
  'zobek',
  'drama',
  'kaček',
  'odhod',
  'temne',
  'dolgo',
  'hrast',
  'spiti',
  'oidij',
  'berač',
  'vzdig',
  'golaž',
  'dimek',
  'dožev',
  'vrbov',
  'pereč',
  'devet',
  'avtor',
  'želod',
  'gosak',
  'zviti',
  'uleks',
  'napuh',
  'dodov',
  'božje',
  'obred',
  'dušek',
  'veter',
  'robec',
  'kačon',
  'kelih',
  'droži',
  'žgati',
  'točen',
  'letno',
  'mazav',
  'podij',
  'vakum',
  'steza',
  'aksel',
  'narek',
  'šnjur',
  'poste',
  'verno',
  'bevsk',
  'občen',
  'juhar',
  'bezeg',
  'glija',
  'futur',
  'sloga',
  'pivec',
  'zabit',
  'krtov',
  'dekle',
  'igriv',
  'čačka',
  'težak',
  'vodja',
  'zbran',
  'nocoj',
  'ratar',
  'ženka',
  'afrik',
  'garač',
  'muzej',
  'grapa',
  'nožar',
  'hrček',
  'gospa',
  'cunja',
  'drveč',
  'jelša',
  'pogin',
  'ožgan',
  'rikša',
  'domač',
  'zaver',
  'razen',
  'bodak',
  'pisoč',
  'nazor',
  'mizen',
  'kopno',
  'falot',
  'gobar',
  'veder',
  'celek',
  'itrij',
  'farsa',
  'obšiv',
  'metje',
  'nitka',
  'nedrc',
  'nekam',
  'pašev',
  'žamet',
  'poved',
  'goved',
  'servo',
  'purji',
  'skozi',
  'sodeč',
  'fižol',
  'srfar',
  'kleti',
  'živio',
  'tehno',
  'strog',
  'strop',
  'črneč',
  'mirno',
  'lovač',
  'roteč',
  'zombi',
  'cmera',
  'mikro',
  'izpit',
  'birma',
  'puran',
  'žgoče',
  'poker',
  'trpen',
  'ščuka',
  'sleme',
  'lužen',
  'geoid',
  'vpust',
  'klica',
  'melos',
  'uteha',
  'fokus',
  'herma',
  'trški',
  'zelje',
  'trdno',
  'grede',
  'huzar',
  'širok',
  'ureči',
  'zmlet',
  'rečje',
  'žnora',
  'pulpa',
  'vespa',
  'alarm',
  'cepič',
  'ničen',
  'odlet',
  'šifra',
  'kripa',
  'alken',
  'jenki',
  'borov',
  'josta',
  'vinar',
  'dogon',
  'zadah',
  'rally',
  'skrit',
  'pamet',
  'topol',
  'objem',
  'volvo',
  'vrveč',
  'šunka',
  'uspel',
  'lečeč',
  'jarec',
  'dekan',
  'dekor',
  'srepo',
  'sorod',
  'kvaša',
  'sliva',
  'mešan',
  'limes',
  'bokal',
  'nadav',
  'najti',
  'bogek',
  'slepo',
  'sklad',
  'moloh',
  'grlec',
  'ležak',
  'želja',
  'reven',
  'pinca',
  'kamra',
  'klaja',
  'kašen',
  'laser',
  'nikar',
  'reden',
  'agent',
  'copat',
  'divje',
  'zmene',
  'splet',
  'izpih',
  'prema',
  'areal',
  'okoli',
  'ratan',
  'molov',
  'kozle',
  'južno',
  'napor',
  'rezan',
  'topaz',
  'čašen',
  'ledar',
  'divji',
  'ripeč',
  'staro',
  'odlit',
  'swing',
  'krile',
  'tenis',
  'zbris',
  'lopar',
  'šogun',
  'čarno',
  'nabit',
  'cvenk',
  'cista',
  'torta',
]
