import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Kako igrati" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ugani besedo v 6 poskusih. Po vsakem ugibanju se barva ploščic spremeni
        in vam pokaže, kako blizu ste iskani besedi.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" status="correct" />
        <Cell value="R" />
        <Cell value="H" />
        <Cell value="I" />
        <Cell value="V" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Črka A je prisotna v iskani besedi in na pravem mestu.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Črka P je prisotna v iskani besedi, a na napačnem mestu.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Ž" />
        <Cell value="R" />
        <Cell value="T" />
        <Cell value="J" status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Črka J ni prisotna v iskani besedi na nobenem mestu.
      </p>
    </BaseModal>
  )
}
